import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

type ReCAPTCHAProps = {
  onVerify: (token: string) => void;
  showNotice?: boolean;
  refreshReCaptcha?: string | number | boolean;
};

export function ReCAPTCHA({ onVerify, showNotice = true, refreshReCaptcha = false }: ReCAPTCHAProps) {
  return (
    <>
      {showNotice && (
        <small>
          Este site é protegido pelo reCAPTCHA e o Google. Consulte as{' '}
          <a href="https://policies.google.com/privacy">Políticas de privacidade</a> e os{' '}
          <a href="https://policies.google.com/terms">Termos de Uso</a>.
        </small>
      )}
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
    </>
  );
}
